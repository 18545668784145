////import Swal from "sweetalert2";
import moment from "moment";
import HelperMixin from '../../../Shared/HelperMixin.vue';
import TrafficChart from "../../Charts/TrafficChart.vue";
import DefaultDoughnutChart from "../../Charts/DefaultDoughnutChart.vue";
import SocialCard from "../../Charts/SocialCard.vue";
import AnalyticsCard from "../../Charts/AnalyticsCard.vue";
import ChannelsChartCard from "../../Charts/ChannelsChartCard.vue";
import RevenueChartCard from "../../Charts/RevenueChartCard.vue";
import SalesChartCard from "../../Charts/SalesChartCard.vue";
import BarChart from "../../Charts/BarChart.vue";
import RadarChart from "../../Charts/RadarChart.vue";
import PolarChart from "../../Charts/PolarChart.vue";
import PolarChart1 from "../../Charts/PolarChart1.vue";
import BarChartHorizontal from "../../Charts/BarChartHorizontal.vue";

export default {
    name: "Transfer",
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        await this.GetAllDiseases(1);
        await this.GetAllBranches();
        await this.GetAllCountries();
        await this.GetAllNationalities();
        await this.GetAllUsers();

        if (this.loginDetails.userType == 4) {
            this.GetFacilitiesInfoUser(this.loginDetails.municipalitiesid);
        }

        this.GetInfo();
        //setInterval(() => this.GetInfo(), 600);

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;

    },
    components: {
        TrafficChart,
        DefaultDoughnutChart,
        SocialCard,
        AnalyticsCard,
        ChannelsChartCard,
        RevenueChartCard,
        SalesChartCard,
        BarChart,
        RadarChart,
        PolarChart,
        PolarChart1,
        BarChartHorizontal,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format("MMMM Do YYYY");
        },
    },
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            Info: [],

            UserId :'',


            DiseasesId: '',
            MunicipalitiesId: '',
            BranchesId: '',
            FacilitiesId: '',
            ByDate: '',
            From: '',
            To: '',



            ScreenTitle: ' التقارير ',
            ScreenTitleSingle: 'تقرير ',
            ScreenTitleSingleAl: 'التقرير ',




            AddDialog: false,
            EditDialog: false,
            InfoDialog: false,

            SelectedItem: '',
            Type: '',






            //Chart One
            ChartHeaderOneLables: 'بيانات الامراض المسجلة داخل النظام [[ البلاغ الأسبوعي ]] ',
            ChartHeaderOneButton: 'عرض المزيد',
            ChartOneLables: [],
            ChartOneDate: [],


            //Chart Two
            ChartHeaderTwoLables: 'بيانات الامراض المسجلة داخل النظام [امراض القسم التاني ] ',
            ChartTwoLables: [],
            ChartTwoDate: [],
            ChartTwo: {
                labels: ['16-20', '21-25', '26-30', '31-36', '36-42', '42+'],
                datasets: {
                    label: 'عدد الحالات المسجلة',
                    data: [15, 20, 12, 60, 20, 15],
                },
            },


            //ChartThree
            

            ChartHeaderThreeLables: "توزيع المرض حسب الأشهر ",
            ChartThreeMonethLables: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            ChartThreeLabels: "عدد الحالات المسجلة",
            ChartThreeData: [0, 0, 0, 0, 0, 0, 0, 0, 0],





            //Chart Four
            ChartFourFullData: {
                labels: ['Adobe', 'Atlassian', 'Slack', 'Spotify', 'Jira'],
                datasets: [{ label: 'Referrals', data: [25, 3, 12, 7, 10] }],
            },
            ChartFourLabels:'',
            ChartFourData: '',



            ChartFifthData: [],

            ChartSixData:[],



            ChartSeven: {
                labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue'],
                datasets: {
                    label: 'الحالات المصابة حسب الجنسية',
                    data: [11, 16, 7, 3, 14],
                },
            },

            ChartSevenLabels:[],
            ChartSevenData:[],

            ChartEghit: {
                labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue'],
                datasets: {
                    label: 'الحالات المصابة حسب حالة التطعيم',
                    data: [11, 16, 7, 3, 14],
                },
            },

            ChartEightLabels: [],
            ChartEightData: [],

            ChartNine: {
                labels: ['16-20', '21-25', '26-30', '31-36', '36-42', '42+'],
                datasets: {
                    label: 'توزيع المرض حسب الاعمار',
                    data: [15, 20, 12, 60, 20, 15],
                },
            },
            ChartNineLabels: [],
            ChartNineData: [],






        };
    },
    methods: {

        async GetMunicipalitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            this.MunicipalitiesId = '';
            this.Municipalities = [];
            await this.GetAllMunicipalities(this.BranchesId);
            this.GetInfo();
        },

        async GetFacilitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(this.MunicipalitiesId);
            this.GetInfo();
        },


        async GetFacilitiesInfoUser(MunicipalitiesId) {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(MunicipalitiesId);
            this.GetInfo();
        },


        GetInfo() {
            this.Info = [];

            if (this.ByDate)
                this.ByDate = this.ChangeDate(this.ByDate);

            if (this.From)
                this.From = this.ChangeDate(this.From);

            if (this.To)
                this.To = this.ChangeDate(this.To);

            this.$blockUI.Start();
            this.$http
                .GetMainReportOne(this.BranchesId, this.MunicipalitiesId, this.FacilitiesId, this.DiseasesId, this.ById, this.ByDate, this.From, this.To, this.UserId)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;

                    this.ChartOneLables = response.data.info.chartOneLabels;
                    this.ChartOneDate = response.data.info.chartOneData;


                    this.ChartTwoLables = response.data.info.chartTwoLabels;
                    this.ChartTwoDate = response.data.info.chartTwoData;
                    this.ChartTwo.labels = response.data.info.chartTwoLabels;
                    this.ChartTwo.datasets.data = response.data.info.chartTwoData;


                    this.ChartThreeData = response.data.info.chartThreeData;

                    this.ChartFourLabels = response.data.info.chartFourLabels;
                    this.ChartFourData = response.data.info.chartFourData;
                    this.ChartFourFullData.labels = response.data.info.chartFourLabels;
                    this.ChartFourFullData.datasets[0].label = "بيانات احصائية";
                    this.ChartFourFullData.datasets[0].data = response.data.info.chartFourData;


                    this.ChartFifthData = response.data.info.chartFifthData;

                    this.ChartSixData = response.data.info.chartSixData;


                   
                    this.ChartSevenLabels = response.data.info.chartSevenLabels;
                    this.ChartSevenData = response.data.info.chartSevenData;
                    this.ChartSeven.labels = response.data.info.chartSevenLabels;
                    this.ChartSeven.datasets.data = response.data.info.chartSevenData;

                    this.ChartEightLabels = response.data.info.chartEightLabels;
                    this.ChartEightData = response.data.info.chartEightData;
                    this.ChartEghit.labels = response.data.info.chartEightLabels;
                    this.ChartEghit.datasets.data = response.data.info.chartEightData;

                    this.ChartNineLabels = response.data.info.chartNineLabels;
                    this.ChartNineData = response.data.info.chartNineData;
                    this.ChartNine.labels = response.data.info.chartNineLabels;
                    this.ChartNine.datasets.data = response.data.info.chartNineData;

                   

                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },




        Refresh() {
            this.MunicipalitiesId = '';
            this.BranchesId = '';
            this.FacilitiesId = '';
            this.DiseasesId = '';
            this.ByDate = '';
            this.From = '';
            this.To = '';
            this.GetInfo();
        },

        ChangeDate(date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("YYYY-MM-DD");
        },

    },
};
