////import Swal from "sweetalert2";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../../Shared/HelperMixin.vue'

export default {
    name: "Add",
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        await this.GetAllDiseases(2);
        await this.GetAllBranches();
        if (this.loginDetails.userType == 4) {
            this.GetFacilitiesInfoUser(this.loginDetails.municipalitiesid);
        }
        
    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format("MMMM Do YYYY");
        },
    },
    data() {
        return {
            Offices: [],


            ruleForm: {
                Id: 0,


                DiseasesId: '',
                MunicipalitiesId: '',
                BranchesId: '',
                FacilitiesId: '',

                CountCases: 0,
                Male: 0,
                Fmale: 0,
                UnknownGender: 0,

                Libyan: 0,
                Nlibyan: 0,
                UnknownNationality: 0,

                Deaths: 0,
                VaccinationStatus: 0,
                NotVaccinationStatus: 0,
                UnknownVaccinationStatus: 0,

                LessOne: 0,
                OneToFive: 0,
                SixToTen: 0,
                ElevenToFifteen: 0,
                SixteenToTwenty: 0,
                TwentyOneToTwentyFive: 0,

                TwentySixToThirty: 0,
                ThirtyOneToThirtyFive: 0,
                ThirtySixToForty: 0,
                FortyOneToFortyFive: 0,
                FortySixToFifty: 0,
                GreaterThanFifty: 0,
                
            },
            rules: {
                
                DiseasesId: this.$helper.RequiredInput('المرض'),
                BranchesId: this.$helper.Required(),
                FacilitiesId: this.$helper.Required(),
                MunicipalitiesId: this.$helper.Required(),

                CountCases: this.$helper.RequiredInput(' عدد الحالات'),
                Male: this.$helper.RequiredInput(' عدد الذكور'),
                Fmale: this.$helper.RequiredInput(' عدد الإناث'),
                UnknownGender: this.$helper.RequiredInput(' غير مفوفي الجنسية '),

                Libyan: this.$helper.RequiredInput(' عدد الليبين  '),
                Nlibyan: this.$helper.RequiredInput(' عدد الاجانب '),
                UnknownNationality: this.$helper.RequiredInput(' عدد الغير معروف الجنسية '),

                Deaths: this.$helper.RequiredInput(' عدد المتوفين '),
                VaccinationStatus: this.$helper.RequiredInput(' عدد المطعمين '),
                NotVaccinationStatus: this.$helper.RequiredInput(' عدد الغير مطعمين '),
                UnknownVaccinationStatus: this.$helper.RequiredInput(' غير معروف حالةالتطعيم '),

                LessOne: this.$helper.Required(),
                OneToFive: this.$helper.Required(),
                SixToTen: this.$helper.Required(),
                ElevenToFifteen: this.$helper.Required(),
                SixteenToTwenty: this.$helper.Required(),
                TwentyOneToTwentyFive: this.$helper.Required(),

                TwentySixToThirty: this.$helper.Required(),
                ThirtyOneToThirtyFive: this.$helper.Required(),
                ThirtySixToForty: this.$helper.Required(),
                FortyOneToFortyFive: this.$helper.Required(),
                FortySixToFifty: this.$helper.Required(),
                GreaterThanFifty: this.$helper.Required(),

            },

        };
    },
    
    methods: {

        async GetMunicipalitiesInfo() {
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];
            this.ruleForm.MunicipalitiesId = '';
            this.Municipalities = [];

            await this.GetAllMunicipalities(this.ruleForm.BranchesId);
        },

        async GetFacilitiesInfo() {
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(this.ruleForm.MunicipalitiesId);
        },

        async GetFacilitiesInfoUser(MunicipalitiesId) {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(MunicipalitiesId);
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    if (this.loginDetails.userType != 1 && this.loginDetails.userType != 4)  {
                        this.ruleForm.FacilitiesId = Number(0);
                    } else {
                        this.ruleForm.FacilitiesId = Number(this.ruleForm.FacilitiesId);
                    }


                    this.ruleForm.Id = Number(0);
                    this.ruleForm.CountCases = Number(this.ruleForm.CountCases);
                    this.ruleForm.Male = Number(this.ruleForm.Male);
                    this.ruleForm.Fmale = Number(this.ruleForm.Fmale);
                    this.ruleForm.UnknownGender = Number(this.ruleForm.UnknownGender);

                    this.ruleForm.Libyan = Number(this.ruleForm.Libyan);
                    this.ruleForm.Nlibyan = Number(this.ruleForm.Nlibyan);
                    this.ruleForm.UnknownNationality = Number(this.ruleForm.UnknownNationality);

                    this.ruleForm.Deaths = Number(this.ruleForm.Deaths);
                    this.ruleForm.VaccinationStatus = Number(this.ruleForm.VaccinationStatus);
                    this.ruleForm.NotVaccinationStatus = Number(this.ruleForm.NotVaccinationStatus);
                    this.ruleForm.UnknownVaccinationStatus = Number(this.ruleForm.UnknownVaccinationStatus);

                    this.ruleForm.LessOne = Number(this.ruleForm.LessOne);
                    this.ruleForm.OneToFive = Number(this.ruleForm.OneToFive);
                    this.ruleForm.SixToTen = Number(this.ruleForm.SixToTen);
                    this.ruleForm.ElevenToFifteen = Number(this.ruleForm.ElevenToFifteen);
                    this.ruleForm.SixteenToTwenty = Number(this.ruleForm.SixteenToTwenty);
                    this.ruleForm.TwentyOneToTwentyFive = Number(this.ruleForm.TwentyOneToTwentyFive);
                    this.ruleForm.TwentySixToThirty = Number(this.ruleForm.TwentySixToThirty);
                    this.ruleForm.ThirtyOneToThirtyFive = Number(this.ruleForm.ThirtyOneToThirtyFive);
                    this.ruleForm.ThirtySixToForty = Number(this.ruleForm.ThirtySixToForty);
                    this.ruleForm.FortyOneToFortyFive = Number(this.ruleForm.FortyOneToFortyFive);
                    this.ruleForm.FortySixToFifty = Number(this.ruleForm.FortySixToFifty);
                    this.ruleForm.GreaterThanFifty = Number(this.ruleForm.GreaterThanFifty);

                    this.$blockUI.Start();
                    this.$http.AddApplicationsWeekly(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();

            this.ruleForm.CountCases = 0;
            this.ruleForm.Male = 0;
            this.ruleForm.Fmale = 0;
            this.ruleForm.UnknownGender = 0;

            this.ruleForm.Libyan = 0;
            this.ruleForm.Nlibyan = 0;
            this.ruleForm.UnknownNationality = 0;

            this.ruleForm.Deaths = 0;
            this.ruleForm.VaccinationStatus = 0;
            this.ruleForm.NotVaccinationStatus = 0;
            this.ruleForm.UnknownVaccinationStatus = 0;
            
            
            //this.ruleForm.OverFive = 0;
            this.ruleForm.LessOne = 0;
            this.ruleForm.OneToFive = 0;
            this.ruleForm.SixToTen = 0;
            this.ruleForm.ElevenToFifteen = 0;
            this.ruleForm.SixteenToTwenty = 0;
            this.ruleForm.TwentyOneToTwentyFive = 0;
            this.ruleForm.TwentySixToThirty = 0;
            this.ruleForm.ThirtyOneToThirtyFive = 0;
            this.ruleForm.ThirtySixToForty = 0;
            this.ruleForm.FortyOneToFortyFive = 0;
            this.ruleForm.FortySixToFifty = 0;
            this.ruleForm.GreaterThanFifty = 0;
        },

      
    },
};
