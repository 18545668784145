import Swal from "sweetalert2";
import moment from "moment";
//import { excelParser } from "./excel-parser";
import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: "AddUser",
    mixins: [HelperMixin],
    async created() {


        this.GetInfo();

        await this.CheckLoginStatus();
        await this.GetAllDiseases(1);
        await this.GetAllBranches();
        await this.GetAllCountries();
        await this.GetAllNationalities();

        if (this.loginDetails.userType == 4) {
            this.GetFacilitiesInfoUser(this.loginDetails.municipalitiesid);
        }


        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;


    },

    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("MMMM Do YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("DD/MM/YYYY");
        },

        momentYear: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("YYYY");
        },

    },


    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,


            Info: [],
            Applications: [],



            DiseasesId: '',
            MunicipalitiesId: '',
            BranchesId: '',
            FacilitiesId: '',
            ByDate: '',

            //Info
            SelectedItem: '',


            AddDialog: false,






            ruleForm: {
                Id: 0,

                DiseasesId: '',
                BranchesId: '',
                MunicipalitiesId: '',
                LBranchesId: '',
                LMunicipalitiesId: '',
                LocalityId: '',
                FacilitiesId: '',

                OccupationId: null,
                OldOccupationId: null,
                PatientsStatusId: null,
                SiteOfBleedingId: null,
                LeishmaniaLesionSitesId: null,
                ChronicDiseasesId: null,
                TraveleToId: null,
                ProceduresId: null,
                RelationshipId: null,
                AnimalId: null,
                AnimalContactId: null,
                AnalysisExaminationsId: null,
                ComplicationsId: null,
                InitialDiagnosisId: null,
                ReferralId: null,
                TreatmentId: null,
                RapidTestId: null,
                SamplesId: null,
                TestId: null,
                ResultId: null,
                SymptomsId: null,



                //OtherDetatils: '',

                Name: '',
                DateOfBirth: '',
                Phone: '',
                Gender: '',

                NationalitieId: 1,
                Nid: '',
                Passport: '',
                Address: '',

                MaritalStatus: '',
                IsPregnant: '',
                School: '',
                PatientAdmitted: '',

                AdmissionOn: null,
                DeathOn: null,
                SymptomsOn: null,
                MaxSizeLeishmaniasisGranule: null,

                NumberLeishmaniasisGranules: null,
                OtherSymptoms: '',
                FirstVisitOn: null,
                PreviousInfections: '',
                ReceivedAntibioticTreatment: '',
                BloodTransfusion: '',
                BloodTransfusionOn: null,
                PreviousHospitalization: '',
                MotherInfectedDuringPregnancy: '',
                MothersMedicalFollowup: '',
                MotherUnderMedicalSupervision: '',
                MotherVisitedMedicalFacility: '',
                ConcomitantViralInfection: '',
                TraveledOutsideArea: '',
                PlaceOfVisit: '',
                VisitOn: null,
                TraveledOutsideCountry: '',
                TravelOn: null,
                ReturnOn: null,
                PlacesVisited: '',
                AcuteFlaccidParalysisCases: '',
                ContactWithSimilarSymptoms: '',
                ContactWithInfectedCase: '',
                FirstContactOn: null,
                OtherCasesWithSameSymptoms: '',
                VaccinationStatus: '',
                OralPolioVaccineDoses: null,
                AdditionalDoses: null,
                NumberOfDoses: null,
                LastDoseOn: null,
                SourceOfInformation: '',
                AntibodiesTested: '',
                MotherVaccinatedAgainstTetanus: '',
                TouchedAnimalWithSymptoms: '',
                ExposureOn: null,
                DealtWithSlaughtering: '',
                ContactWithAnimals: '',
                BiteStingOn: null,
                SeenAscorpion: null,
                ColorOfScorpion: '',
                SizeOfScorpion: null,
                LocationOfAnimal: '',
                AnimalOwnerNameAddress: '',
                AnimalDeathsLastMonth: '',
                AnimalDeathsLastMonthCount: null,
                AnimalMiscarriagesLastMonth: '',
                AnimalMiscarriagesLastMonthCount: null,
                AnimalStatus: '',
                AnimalVaccinated: '',
                AnimalLabConfirmedInfected: '',
                ConsumedAnimalProducts: '',
                ConsumedMeatFromSickAnimal: '',
                FoodSource: '',
                SharedMeal: '',
                FoodTypes: '',
                MealOn: null,
                DrinkingWaterSource: null,
                OtherDrinkingWaterSource: '',
                WaterBasinsInArea: '',
                SleepsInMosquitoNet: '',
                SleepingArea: '',
                Sanitation: '',
                NumberHydatidCysts: null,
                LocationOfInjuryId: null,
                PreviousSurgeryHydatidCysts: '',
                WoundSignificance: '',
                SkinLesionsSameDevelopment: '',
                SkinLesionsSameSize: '',
                SkinLesionsDeep: '',
                LocationSkinLesionsId: null,
                DevelopedUlcers: '',
                HasAids: '',
                AwareOfDisease: '',
                SexualIntercourseLastThreeMonths: '',
                RelationshipInsideLibya: '',
                BirthInHealthFacility: '',
                BirthUnderMedicalSupervision: '',
                UmbilicalCordCutMethod: '',
                UmbilicalCordBandagedMethod: '',
                NewbornCryingBreastfeeding: '',
                NewbornBreastfeedingProblems: '',
                NewbornConvulsions: '',
                ExaminedByHealthAuthority: '',
                ContaminatedWound: '',
                ForeignBodyEntered: '',
                SharedUnsterileNeedles: '',
                ReceivedLettersParcels: '',
                InjuryDetectionMethod: '',
            },
            rules: {

                DiseasesId: this.$helper.RequiredInput('المرض'),
                BranchesId: this.$helper.Required(),
                MunicipalitiesId: this.$helper.Required(),
                LBranchesId: this.$helper.Required(),
                LMunicipalitiesId: this.$helper.Required(),
                LocalityId: this.$helper.Required(),
                FacilitiesId: this.$helper.Required(),


                //OtherDetatils: '',

                Name: this.$helper.Required(),
                DateOfBirth: this.$helper.Required(),
                Phone: [
                    {
                        required: true,
                        message: "الرجاء إدخال رقم الهاتف",
                        trigger: "blur",
                    },
                    {
                        min: 9,
                        max: 13,
                        message: "يجب ان يكون طول رقم الهاتف 9 ارقام علي الاقل",
                        trigger: "blur",
                    },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' }
                ],
                Gender: this.$helper.Required(),

                NationalitieId: this.$helper.RequiredInput('الجنسية'),
                Nid: this.$helper.NID(),
                Passport: this.$helper.RequiredInput('رقم جواز السفر او رقم الهوية'),
                Address: this.$helper.Required(),

                MaritalStatus: '',
                IsPregnant: this.$helper.Required(),
                School: '',
                PatientAdmitted: this.$helper.Required(),

                AdmissionOn: this.$helper.Required(),
                DeathOn: this.$helper.Required(),

            },


        };
    },


    methods: {

        async GetMunicipalitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            this.MunicipalitiesId = '';
            this.Municipalities = [];
            await this.GetAllMunicipalities(this.BranchesId);
            this.GetInfo();
        },

        async GetFacilitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(this.MunicipalitiesId);
            this.GetInfo();
        },

        async GetFacilitiesInfoUser(MunicipalitiesId) {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(MunicipalitiesId);
            this.GetInfo();
        },


        async LGetMunicipalitiesInfo() {
            this.ruleForm.LocalityId = '';
            this.Locality = [];
            this.ruleForm.LMunicipalitiesId = '';
            await this.LGetAllMunicipalities(this.ruleForm.LBranchesId);
        },

        async GetAllLocalityInfo() {
            this.ruleForm.LocalityId = '';
            await this.GetAllLocality(this.ruleForm.LMunicipalitiesId);
        },



        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (this.byDate)
                this.byDate = this.ChangeDate(this.byDate);

            this.$blockUI.Start();
            this.$http
                .GetRequest(this.pageNo, this.pageSize, this.BranchesId, this.MunicipalitiesId, this.FacilitiesId, this.DiseasesId, this.ById, this.ByDate)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },


        async ChangeDiseases() {
            await this.GetAllAnalysisExaminations(this.ruleForm.DiseasesId);
            await this.GetAllAnimals(this.ruleForm.DiseasesId);
            await this.GetAllChronicDiseases(this.ruleForm.DiseasesId);
            await this.GetAllComplications(this.ruleForm.DiseasesId);
            await this.GetAllInitialDiagnosis(this.ruleForm.DiseasesId);
            await this.GetAllLaboratories(this.ruleForm.MunicipalitiesId);
            await this.GetAllLesionSites(this.ruleForm.DiseasesId);
            await this.GetAllOccupations(this.ruleForm.DiseasesId);
            await this.GetAllPatientsStatus(this.ruleForm.DiseasesId);
            await this.GetAllProcedures(this.ruleForm.DiseasesId);
            await this.GetAllRapidTests(this.ruleForm.DiseasesId);
            await this.GetAllReferrals(this.ruleForm.DiseasesId);
            await this.GetAllRelationship(this.ruleForm.DiseasesId);
            await this.GetAllResults(this.ruleForm.DiseasesId);
            await this.GetAllSamples(this.ruleForm.DiseasesId);
            await this.GetAllSiteOfBleedings(this.ruleForm.DiseasesId);
            await this.GetAllTests(this.ruleForm.DiseasesId);
            await this.GetAllTreatments(this.ruleForm.DiseasesId);
            await this.GetAllAnimalContacts(this.ruleForm.DiseasesId);
            await this.GetAllSymptoms(this.ruleForm.DiseasesId);
        },


        OpenAddDialog(item) {
            this.ruleForm.RequestsId = item.id;
            this.ruleForm.DiseasesId = item.diseasesId;
            this.ChangeDiseases();
            this.ruleForm.Name = '';
            this.ruleForm.DateOfBirth = '';
            this.ruleForm.Gender = '';
            this.ruleForm.Nid = '';
            this.ruleForm.Passport = '';
            this.ruleForm.Address = '';
            this.ruleForm.MaritalStatus = '';
            this.AddDialog = true;
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.ruleForm.Id = Number(0);
                    this.ruleForm.FacilitiesId = Number(0);
                    this.ruleForm.DiseasesId = Number(0);






                    if (!this.ruleForm.MaritalStatus)
                        this.ruleForm.MaritalStatus = Number(0);

                    if (!this.ruleForm.IsPregnant) {
                        this.ruleForm.IsPregnant = Number(0);
                    } else {
                        this.ruleForm.IsPregnant = Number(this.ruleForm.IsPregnant);
                    }

                    if (!this.ruleForm.PatientAdmitted) {
                        this.ruleForm.PatientAdmitted = Number(0);
                    } else {
                        this.ruleForm.PatientAdmitted = Number(this.ruleForm.PatientAdmitted);
                    }

                    if (this.ruleForm.MaxSizeLeishmaniasisGranule)
                        this.ruleForm.MaxSizeLeishmaniasisGranule = Number(this.ruleForm.MaxSizeLeishmaniasisGranule);

                    if (this.ruleForm.NumberLeishmaniasisGranules)
                        this.ruleForm.NumberLeishmaniasisGranules = Number(this.ruleForm.NumberLeishmaniasisGranules);


                    if (!this.ruleForm.Gender) {
                        this.ruleForm.Gender = Number(0);
                    } else {
                        this.ruleForm.Gender = Number(this.ruleForm.Gender);
                    }

                    if (!this.ruleForm.PreviousInfections) {
                        this.ruleForm.PreviousInfections = Number(0);
                    } else {
                        this.ruleForm.PreviousInfections = Number(this.ruleForm.PreviousInfections);
                    }

                    if (!this.ruleForm.ReceivedAntibioticTreatment) {
                        this.ruleForm.ReceivedAntibioticTreatment = Number(0);
                    } else {
                        this.ruleForm.ReceivedAntibioticTreatment = Number(this.ruleForm.ReceivedAntibioticTreatment);
                    }

                    if (!this.ruleForm.BloodTransfusion) {
                        this.ruleForm.BloodTransfusion = Number(0);
                    } else {
                        this.ruleForm.BloodTransfusion = Number(this.ruleForm.BloodTransfusion);
                    }

                    if (!this.ruleForm.PreviousHospitalization) {
                        this.ruleForm.PreviousHospitalization = Number(0);
                    } else {
                        this.ruleForm.PreviousHospitalization = Number(this.ruleForm.PreviousHospitalization);
                    }


                    if (!this.ruleForm.MotherInfectedDuringPregnancy) {
                        this.ruleForm.MotherInfectedDuringPregnancy = Number(0);
                    } else {
                        this.ruleForm.MotherInfectedDuringPregnancy = Number(this.ruleForm.MotherInfectedDuringPregnancy);
                    }

                    if (!this.ruleForm.MotherUnderMedicalSupervision) {
                        this.ruleForm.MotherUnderMedicalSupervision = Number(0);
                    } else {
                        this.ruleForm.MotherUnderMedicalSupervision = Number(this.ruleForm.MotherUnderMedicalSupervision);
                    }

                    if (!this.ruleForm.MotherVisitedMedicalFacility) {
                        this.ruleForm.MotherVisitedMedicalFacility = Number(0);
                    } else {
                        this.ruleForm.MotherVisitedMedicalFacility = Number(this.ruleForm.MotherVisitedMedicalFacility);
                    }

                    if (!this.ruleForm.ConcomitantViralInfection) {
                        this.ruleForm.ConcomitantViralInfection = Number(0);
                    } else {
                        this.ruleForm.ConcomitantViralInfection = Number(this.ruleForm.ConcomitantViralInfection);
                    }

                    if (!this.ruleForm.TraveledOutsideArea) {
                        this.ruleForm.TraveledOutsideArea = Number(0);
                    } else {
                        this.ruleForm.TraveledOutsideArea = Number(this.ruleForm.TraveledOutsideArea);
                    }

                    if (!this.ruleForm.TraveledOutsideCountry) {
                        this.ruleForm.TraveledOutsideCountry = Number(0);
                    } else {
                        this.ruleForm.TraveledOutsideCountry = Number(this.ruleForm.TraveledOutsideCountry);
                    }

                    if (!this.ruleForm.AcuteFlaccidParalysisCases) {
                        this.ruleForm.AcuteFlaccidParalysisCases = Number(0);
                    } else {
                        this.ruleForm.AcuteFlaccidParalysisCases = Number(this.ruleForm.AcuteFlaccidParalysisCases);
                    }

                    if (!this.ruleForm.ContactWithSimilarSymptoms) {
                        this.ruleForm.ContactWithSimilarSymptoms = Number(0);
                    } else {
                        this.ruleForm.ContactWithSimilarSymptoms = Number(this.ruleForm.ContactWithSimilarSymptoms);
                    }

                    if (!this.ruleForm.ContactWithInfectedCase) {
                        this.ruleForm.ContactWithInfectedCase = Number(0);
                    } else {
                        this.ruleForm.ContactWithInfectedCase = Number(this.ruleForm.ContactWithInfectedCase);
                    }

                    if (!this.ruleForm.OtherCasesWithSameSymptoms) {
                        this.ruleForm.OtherCasesWithSameSymptoms = Number(0);
                    } else {
                        this.ruleForm.OtherCasesWithSameSymptoms = Number(this.ruleForm.OtherCasesWithSameSymptoms);
                    }

                    if (!this.ruleForm.VaccinationStatus) {
                        this.ruleForm.VaccinationStatus = Number(0);
                    } else {
                        this.ruleForm.VaccinationStatus = Number(this.ruleForm.VaccinationStatus);
                    }

                    if (this.ruleForm.OralPolioVaccineDoses)
                        this.ruleForm.OralPolioVaccineDoses = Number(this.ruleForm.OralPolioVaccineDoses);

                    if (this.ruleForm.AdditionalDoses)
                        this.ruleForm.AdditionalDoses = Number(this.ruleForm.AdditionalDoses);

                    if (this.ruleForm.NumberOfDoses)
                        this.ruleForm.NumberOfDoses = Number(this.ruleForm.NumberOfDoses);


                    if (!this.ruleForm.AntibodiesTested) {
                        this.ruleForm.AntibodiesTested = Number(0);
                    } else {
                        this.ruleForm.AntibodiesTested = Number(this.ruleForm.AntibodiesTested);
                    }

                    if (!this.ruleForm.MotherVaccinatedAgainstTetanus) {
                        this.ruleForm.MotherVaccinatedAgainstTetanus = Number(0);
                    } else {
                        this.ruleForm.MotherVaccinatedAgainstTetanus = Number(this.ruleForm.MotherVaccinatedAgainstTetanus);
                    }

                    if (!this.ruleForm.TouchedAnimalWithSymptoms) {
                        this.ruleForm.TouchedAnimalWithSymptoms = Number(0);
                    } else {
                        this.ruleForm.TouchedAnimalWithSymptoms = Number(this.ruleForm.TouchedAnimalWithSymptoms);
                    }

                    if (!this.ruleForm.DealtWithSlaughtering) {
                        this.ruleForm.DealtWithSlaughtering = Number(0);
                    } else {
                        this.ruleForm.DealtWithSlaughtering = Number(this.ruleForm.DealtWithSlaughtering);
                    }

                    if (!this.ruleForm.ContactWithAnimals) {
                        this.ruleForm.ContactWithAnimals = Number(0);
                    } else {
                        this.ruleForm.ContactWithAnimals = Number(this.ruleForm.ContactWithAnimals);
                    }

                    if (!this.ruleForm.SeenAscorpion) {
                        this.ruleForm.SeenAscorpion = Number(0);
                    } else {
                        this.ruleForm.SeenAscorpion = Number(this.ruleForm.SeenAscorpion);
                    }

                    if (this.ruleForm.SizeOfScorpion)
                        this.ruleForm.SizeOfScorpion = Number(this.ruleForm.SizeOfScorpion);

                    if (!this.ruleForm.AnimalDeathsLastMonth) {
                        this.ruleForm.AnimalDeathsLastMonth = Number(0);
                    } else {
                        this.ruleForm.AnimalDeathsLastMonth = Number(this.ruleForm.AnimalDeathsLastMonth);
                    }

                    if (this.ruleForm.AnimalDeathsLastMonthCount)
                        this.ruleForm.AnimalDeathsLastMonthCount = Number(this.ruleForm.AnimalDeathsLastMonthCount);

                    if (!this.ruleForm.AnimalMiscarriagesLastMonth) {
                        this.ruleForm.AnimalMiscarriagesLastMonth = Number(0);
                    } else {
                        this.ruleForm.AnimalMiscarriagesLastMonth = Number(this.ruleForm.AnimalMiscarriagesLastMonth);
                    }

                    if (this.ruleForm.AnimalMiscarriagesLastMonthCount)
                        this.ruleForm.AnimalMiscarriagesLastMonthCount = Number(this.ruleForm.AnimalMiscarriagesLastMonthCount);

                    if (!this.ruleForm.AnimalVaccinated) {
                        this.ruleForm.AnimalVaccinated = Number(0);
                    } else {
                        this.ruleForm.AnimalVaccinated = Number(this.ruleForm.AnimalVaccinated);
                    }

                    if (!this.ruleForm.AnimalLabConfirmedInfected) {
                        this.ruleForm.AnimalLabConfirmedInfected = Number(0);
                    } else {
                        this.ruleForm.AnimalLabConfirmedInfected = Number(this.ruleForm.AnimalLabConfirmedInfected);
                    }

                    if (!this.ruleForm.ConsumedAnimalProducts) {
                        this.ruleForm.ConsumedAnimalProducts = Number(0);
                    } else {
                        this.ruleForm.ConsumedAnimalProducts = Number(this.ruleForm.ConsumedAnimalProducts);
                    }

                    if (!this.ruleForm.ConsumedMeatFromSickAnimal) {
                        this.ruleForm.ConsumedMeatFromSickAnimal = Number(0);
                    } else {
                        this.ruleForm.ConsumedMeatFromSickAnimal = Number(this.ruleForm.ConsumedMeatFromSickAnimal);
                    }

                    if (!this.ruleForm.SharedMeal) {
                        this.ruleForm.SharedMeal = Number(0);
                    } else {
                        this.ruleForm.SharedMeal = Number(this.ruleForm.SharedMeal);
                    }

                    if (!this.ruleForm.WaterBasinsInArea) {
                        this.ruleForm.WaterBasinsInArea = Number(0);
                    } else {
                        this.ruleForm.WaterBasinsInArea = Number(this.ruleForm.WaterBasinsInArea);
                    }

                    if (!this.ruleForm.SleepsInMosquitoNet) {
                        this.ruleForm.SleepsInMosquitoNet = Number(0);
                    } else {
                        this.ruleForm.SleepsInMosquitoNet = Number(this.ruleForm.SleepsInMosquitoNet);
                    }

                    if (!this.ruleForm.SleepingArea) {
                        this.ruleForm.SleepingArea = Number(0);
                    } else {
                        this.ruleForm.SleepingArea = Number(this.ruleForm.SleepingArea);
                    }

                    if (!this.ruleForm.Sanitation) {
                        this.ruleForm.Sanitation = Number(0);
                    } else {
                        this.ruleForm.Sanitation = Number(this.ruleForm.Sanitation);
                    }

                    if (this.ruleForm.NumberHydatidCysts)
                        this.ruleForm.NumberHydatidCysts = Number(this.ruleForm.NumberHydatidCysts);

                    if (!this.ruleForm.PreviousSurgeryHydatidCysts) {
                        this.ruleForm.PreviousSurgeryHydatidCysts = Number(0);
                    } else {
                        this.ruleForm.PreviousSurgeryHydatidCysts = Number(this.ruleForm.PreviousSurgeryHydatidCysts);
                    }

                    if (!this.ruleForm.SkinLesionsSameDevelopment) {
                        this.ruleForm.SkinLesionsSameDevelopment = Number(0);
                    } else {
                        this.ruleForm.SkinLesionsSameDevelopment = Number(this.ruleForm.SkinLesionsSameDevelopment);
                    }

                    if (!this.ruleForm.SkinLesionsSameSize) {
                        this.ruleForm.SkinLesionsSameSize = Number(0);
                    } else {
                        this.ruleForm.SkinLesionsSameSize = Number(this.ruleForm.SkinLesionsSameSize);
                    }


                    if (!this.ruleForm.SkinLesionsSameSize) {
                        this.ruleForm.SkinLesionsSameSize = Number(0);
                    } else {
                        this.ruleForm.SkinLesionsSameSize = Number(this.ruleForm.SkinLesionsSameSize);
                    }

                    if (!this.ruleForm.SkinLesionsDeep) {
                        this.ruleForm.SkinLesionsDeep = Number(0);
                    } else {
                        this.ruleForm.SkinLesionsDeep = Number(this.ruleForm.SkinLesionsDeep);
                    }

                    if (!this.ruleForm.DevelopedUlcers) {
                        this.ruleForm.DevelopedUlcers = Number(0);
                    } else {
                        this.ruleForm.DevelopedUlcers = Number(this.ruleForm.DevelopedUlcers);
                    }

                    if (!this.ruleForm.HasAids) {
                        this.ruleForm.HasAids = Number(0);
                    } else {
                        this.ruleForm.HasAids = Number(this.ruleForm.HasAids);
                    }

                    if (!this.ruleForm.AwareOfDisease) {
                        this.ruleForm.AwareOfDisease = Number(0);
                    } else {
                        this.ruleForm.AwareOfDisease = Number(this.ruleForm.AwareOfDisease);
                    }

                    if (!this.ruleForm.SexualIntercourseLastThreeMonths) {
                        this.ruleForm.SexualIntercourseLastThreeMonths = Number(0);
                    } else {
                        this.ruleForm.SexualIntercourseLastThreeMonths = Number(this.ruleForm.SexualIntercourseLastThreeMonths);
                    }

                    if (!this.ruleForm.RelationshipInsideLibya) {
                        this.ruleForm.RelationshipInsideLibya = Number(0);
                    } else {
                        this.ruleForm.RelationshipInsideLibya = Number(this.ruleForm.RelationshipInsideLibya);
                    }

                    if (!this.ruleForm.BirthInHealthFacility) {
                        this.ruleForm.BirthInHealthFacility = Number(0);
                    } else {
                        this.ruleForm.BirthInHealthFacility = Number(this.ruleForm.BirthInHealthFacility);
                    }

                    if (!this.ruleForm.BirthUnderMedicalSupervision) {
                        this.ruleForm.BirthUnderMedicalSupervision = Number(0);
                    } else {
                        this.ruleForm.BirthUnderMedicalSupervision = Number(this.ruleForm.BirthUnderMedicalSupervision);
                    }

                    if (!this.ruleForm.NewbornCryingBreastfeeding) {
                        this.ruleForm.NewbornCryingBreastfeeding = Number(0);
                    } else {
                        this.ruleForm.NewbornCryingBreastfeeding = Number(this.ruleForm.NewbornCryingBreastfeeding);
                    }

                    if (!this.ruleForm.NewbornBreastfeedingProblems) {
                        this.ruleForm.NewbornBreastfeedingProblems = Number(0);
                    } else {
                        this.ruleForm.NewbornBreastfeedingProblems = Number(this.ruleForm.NewbornBreastfeedingProblems);
                    }

                    if (!this.ruleForm.NewbornConvulsions) {
                        this.ruleForm.NewbornConvulsions = Number(0);
                    } else {
                        this.ruleForm.NewbornConvulsions = Number(this.ruleForm.NewbornConvulsions);
                    }

                    if (!this.ruleForm.ExaminedByHealthAuthority) {
                        this.ruleForm.ExaminedByHealthAuthority = Number(0);
                    } else {
                        this.ruleForm.ExaminedByHealthAuthority = Number(this.ruleForm.ExaminedByHealthAuthority);
                    }

                    if (!this.ruleForm.ContaminatedWound) {
                        this.ruleForm.ContaminatedWound = Number(0);
                    } else {
                        this.ruleForm.ContaminatedWound = Number(this.ruleForm.ContaminatedWound);
                    }

                    if (!this.ruleForm.ForeignBodyEntered) {
                        this.ruleForm.ForeignBodyEntered = Number(0);
                    } else {
                        this.ruleForm.ForeignBodyEntered = Number(this.ruleForm.ForeignBodyEntered);
                    }

                    if (!this.ruleForm.SharedUnsterileNeedles) {
                        this.ruleForm.SharedUnsterileNeedles = Number(0);
                    } else {
                        this.ruleForm.SharedUnsterileNeedles = Number(this.ruleForm.SharedUnsterileNeedles);
                    }

                    if (!this.ruleForm.ReceivedLettersParcels) {
                        this.ruleForm.ReceivedLettersParcels = Number(0);
                    } else {
                        this.ruleForm.ReceivedLettersParcels = Number(this.ruleForm.ReceivedLettersParcels);
                    }


                    this.$blockUI.Start();
                    this.$http.AddRequestApplications(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.AddDialog = false;
                            this.GetInfo(this.pageNo);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },



        //Info
        ViewInfo(item) {
            this.SelectedItem = item;
            this.ruleForm.DiseasesId = this.SelectedItem.diseasesId;
            this.ChangeDiseases();
            this.state = 2;
            this.GetApplicationsRequest();
        },


        GetApplicationsRequest() {

            this.$blockUI.Start();
            this.$http
                .GetApplicationsRequest(this.SelectedItem.id)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Applications = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },


        OpenEditDilaog(item) {

            this.ruleForm.Id = item.id;
            this.ruleForm.DiseasesId = item.diseasesId;
            this.ChangeDiseases();
            this.ruleForm.OccupationId = item.occupationId;
            this.ruleForm.OldOccupationId = item.oldOccupationId;
            this.ruleForm.PatientsStatusId = item.patientsStatusId;
            this.ruleForm.SiteOfBleedingId = item.siteOfBleedingId;
            this.ruleForm.LeishmaniaLesionSitesId = item.leishmaniaLesionSitesId;
            this.ruleForm.ChronicDiseasesId = item.chronicDiseasesId;
            this.ruleForm.TraveleToId = item.traveleToId;
            this.ruleForm.ProceduresId = item.proceduresId;
            this.ruleForm.RelationshipId = item.relationshipId;
            this.ruleForm.AnimalId = item.animalId;
            this.ruleForm.AnimalContactId = item.animalContactId;
            this.ruleForm.AnalysisExaminationsId = item.analysisExaminationsId;
            this.ruleForm.ComplicationsId = item.complicationsId;
            this.ruleForm.InitialDiagnosisId = item.initialDiagnosisId;
            this.ruleForm.ReferralId = item.referralId;
            this.ruleForm.TreatmentId = item.treatmentId;
            this.ruleForm.RapidTestId = item.rapidTestId;
            this.ruleForm.SamplesId = item.samplesId;
            this.ruleForm.TestId = item.testId;
            this.ruleForm.ResultId = item.resultId;
            this.ruleForm.SymptomsId = item.symptomsId;

            this.ruleForm.Name = item.name;
            this.ruleForm.DateOfBirth = item.dateOfBirth;
            this.ruleForm.Phone = item.phone;
            this.ruleForm.Gender = item.gender;

            this.ruleForm.NationalitieId = item.nationalitieId;
            this.ruleForm.Nid = item.nid;
            this.ruleForm.Passport = item.passport;
            this.ruleForm.Address = item.address;

            this.ruleForm.MaritalStatus = item.maritalStatus;
            this.ruleForm.IsPregnant = item.isPregnant;
            this.ruleForm.School = item.school;
            this.ruleForm.PatientAdmitted = item.patientAdmitted;

            this.ruleForm.AdmissionOn = item.admissionOn;
            this.ruleForm.DeathOn = item.deathOn;
            this.ruleForm.SymptomsOn = item.symptomsOn;
            this.ruleForm.MaxSizeLeishmaniasisGranule = item.maxSizeLeishmaniasisGranule;

            this.ruleForm.NumberLeishmaniasisGranules = item.numberLeishmaniasisGranules;
            this.ruleForm.OtherSymptoms = item.otherSymptoms;
            this.ruleForm.FirstVisitOn = item.firstVisitOn;
            this.ruleForm.PreviousInfections = item.previousInfections;
            this.ruleForm.ReceivedAntibioticTreatment = item.receivedAntibioticTreatment;
            this.ruleForm.BloodTransfusion = item.bloodTransfusion;
            this.ruleForm.BloodTransfusionOn = item.bloodTransfusionOn;
            this.ruleForm.PreviousHospitalization = item.previousHospitalization;
            this.ruleForm.MotherInfectedDuringPregnancy = item.motherInfectedDuringPregnancy;
            this.ruleForm.MothersMedicalFollowup = item.mothersMedicalFollowup;
            this.ruleForm.MotherUnderMedicalSupervision = item.motherUnderMedicalSupervision;
            this.ruleForm.MotherVisitedMedicalFacility = item.motherVisitedMedicalFacility;
            this.ruleForm.ConcomitantViralInfection = item.concomitantViralInfection;
            this.ruleForm.TraveledOutsideArea = item.traveledOutsideArea;
            this.ruleForm.PlaceOfVisit = item.placeOfVisit;
            this.ruleForm.VisitOn = item.visitOn;
            this.ruleForm.TraveledOutsideCountry = item.traveledOutsideCountry;
            this.ruleForm.TravelOn = item.travelOn;
            this.ruleForm.ReturnOn = item.returnOn;
            this.ruleForm.PlacesVisited = item.placesVisited;
            this.ruleForm.AcuteFlaccidParalysisCases = item.acuteFlaccidParalysisCases;
            this.ruleForm.ContactWithSimilarSymptoms = item.contactWithSimilarSymptoms;
            this.ruleForm.ContactWithInfectedCase = item.contactWithInfectedCase;
            this.ruleForm.FirstContactOn = item.firstContactOn;
            this.ruleForm.OtherCasesWithSameSymptoms = item.otherCasesWithSameSymptoms;
            this.ruleForm.VaccinationStatus = item.vaccinationStatus;
            this.ruleForm.OralPolioVaccineDoses = item.oralPolioVaccineDoses;
            this.ruleForm.AdditionalDoses = item.additionalDoses;
            this.ruleForm.NumberOfDoses = item.numberOfDoses;
            this.ruleForm.LastDoseOn = item.lastDoseOn;
            this.ruleForm.SourceOfInformation = item.sourceOfInformation;
            this.ruleForm.AntibodiesTested = item.antibodiesTested;
            this.ruleForm.MotherVaccinatedAgainstTetanus = item.motherVaccinatedAgainstTetanus;
            this.ruleForm.TouchedAnimalWithSymptoms = item.touchedAnimalWithSymptoms;
            this.ruleForm.ExposureOn = item.exposureOn;
            this.ruleForm.DealtWithSlaughtering = item.dealtWithSlaughtering;
            this.ruleForm.ContactWithAnimals = item.contactWithAnimals;
            this.ruleForm.BiteStingOn = item.biteStingOn;
            this.ruleForm.SeenAscorpion = item.seenAscorpion;
            this.ruleForm.ColorOfScorpion = item.colorOfScorpion;
            this.ruleForm.SizeOfScorpion = item.sizeOfScorpion;
            this.ruleForm.LocationOfAnimal = item.locationOfAnimal;
            this.ruleForm.AnimalOwnerNameAddress = item.animalOwnerNameAddress;
            this.ruleForm.AnimalDeathsLastMonth = item.animalDeathsLastMonth;
            this.ruleForm.AnimalDeathsLastMonthCount = item.animalDeathsLastMonthCount;
            this.ruleForm.AnimalMiscarriagesLastMonth = item.animalMiscarriagesLastMonth;
            this.ruleForm.AnimalMiscarriagesLastMonthCount = item.animalMiscarriagesLastMonthCount;
            this.ruleForm.AnimalStatus = item.animalStatus;
            this.ruleForm.AnimalVaccinated = item.animalVaccinated;
            this.ruleForm.AnimalLabConfirmedInfected = item.animalLabConfirmedInfected;
            this.ruleForm.ConsumedAnimalProducts = item.consumedAnimalProducts;
            this.ruleForm.ConsumedMeatFromSickAnimal = item.consumedMeatFromSickAnimal;
            this.ruleForm.FoodSource = item.foodSource;
            this.ruleForm.SharedMeal = item.sharedMeal;
            this.ruleForm.FoodTypes = item.foodTypes;
            this.ruleForm.MealOn = item.mealOn;
            this.ruleForm.DrinkingWaterSource = item.drinkingWaterSource;
            this.ruleForm.OtherDrinkingWaterSource = item.otherDrinkingWaterSource;
            this.ruleForm.WaterBasinsInArea = item.waterBasinsInArea;
            this.ruleForm.SleepsInMosquitoNet = item.sleepsInMosquitoNet;
            this.ruleForm.SleepingArea = item.sleepingArea;
            this.ruleForm.Sanitation = item.sanitation;
            this.ruleForm.NumberHydatidCysts = item.numberHydatidCysts;
            this.ruleForm.LocationOfInjuryId = item.locationOfInjuryId;
            this.ruleForm.PreviousSurgeryHydatidCysts = item.previousSurgeryHydatidCysts;
            this.ruleForm.WoundSignificance = item.woundSignificance;
            this.ruleForm.SkinLesionsSameDevelopment = item.skinLesionsSameDevelopment;
            this.ruleForm.SkinLesionsSameSize = item.skinLesionsSameSize;
            this.ruleForm.SkinLesionsDeep = item.skinLesionsDeep;
            this.ruleForm.LocationSkinLesionsId = item.locationSkinLesionsId;
            this.ruleForm.DevelopedUlcers = item.developedUlcers;
            this.ruleForm.HasAids = item.hasAids;
            this.ruleForm.AwareOfDisease = item.awareOfDisease;
            this.ruleForm.SexualIntercourseLastThreeMonths = item.sexualIntercourseLastThreeMonths;
            this.ruleForm.RelationshipInsideLibya = item.relationshipInsideLibya;
            this.ruleForm.BirthInHealthFacility = item.birthInHealthFacility;
            this.ruleForm.BirthUnderMedicalSupervision = item.birthUnderMedicalSupervision;
            this.ruleForm.UmbilicalCordCutMethod = item.umbilicalCordCutMethod;
            this.ruleForm.UmbilicalCordBandagedMethod = item.umbilicalCordBandagedMethod;
            this.ruleForm.NewbornCryingBreastfeeding = item.newbornCryingBreastfeeding;
            this.ruleForm.NewbornBreastfeedingProblems = item.newbornBreastfeedingProblems;
            this.ruleForm.NewbornConvulsions = item.newbornConvulsions;
            this.ruleForm.ExaminedByHealthAuthority = item.examinedByHealthAuthority;
            this.ruleForm.ContaminatedWound = item.contaminatedWound;
            this.ruleForm.ForeignBodyEntered = item.foreignBodyEntered;
            this.ruleForm.SharedUnsterileNeedles = item.sharedUnsterileNeedles;
            this.ruleForm.ReceivedLettersParcels = item.receivedLettersParcels;
            this.ruleForm.InjuryDetectionMethod = item.injuryDetectionMethod;

            this.state = 3;

        },  




        DeleteApplications(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteApplications(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },



















        Refresh() {
            this.MunicipalitiesId = '';
            this.BranchesId = '';
            this.FacilitiesId = '';
            this.DiseasesId = '';
            this.ByDate = '';
            this.GetInfo();
        },

        Delete(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteJobs(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },









        











































        Back() {
            this.state = 0;
            this.SelectedItem = '';
            this.GetInfo(this.pageNo);
        },
        BackSetp() {
            this.state = 1;
        },

        ChangeDate(date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("YYYY-MM-DD");
        },






    },
};
