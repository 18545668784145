import Swal from "sweetalert2";
import moment from "moment";
//import { excelParser } from "./excel-parser";
import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: "AddUser",
    mixins: [HelperMixin],
    async created() {



        this.GetInfo();

        await this.CheckLoginStatus();
        await this.GetAllDiseases(1);
        await this.GetAllBranches();




        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        this.dateNow = mm + "/" + dd + "/" + yyyy;


    },

    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("MMMM Do YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("DD/MM/YYYY");
        },

        momentYear: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("YYYY");
        },

    },  


    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,


            Info: [],
            Applications: [],


            DiseasesId: '',
            MunicipalitiesId: '',
            BranchesId: '',
            FacilitiesId: '',
            ByDate: '',




            //Info
            SelectedItem: '',



            AddDilog: false,
            ViewDialog: false,
            AttachmentDiloag: false,
            dialogImageUrl: '',
            dialogVisible: false,




            LabruleForm: {
                Id: '',
                DeliverdOn: '',
                Result: '',
                ResultDescriptions: '',
                ResultsId: '',
                TestsId: '',

            },
            Labrules: {

                DeliverdOn: this.$helper.Required(),
                Result: this.$helper.Required(),
                ResultDescriptions: this.$helper.Required(),
                ResultsId: this.$helper.Required(),
                TestsId: this.$helper.Required(),

            },


        };
    },


    methods: {

        async GetMunicipalitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            this.MunicipalitiesId = '';
            this.Municipalities = [];
            await this.GetAllMunicipalities(this.BranchesId);
            this.GetInfo();
        },

        async GetFacilitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(this.MunicipalitiesId);
            this.GetInfo();
        },

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (this.byDate)
                this.byDate = this.ChangeDate(this.byDate);

            this.$blockUI.Start();
            this.$http
                .GetApplicationsLabs(this.pageNo, this.pageSize, this.BranchesId, this.MunicipalitiesId, this.FacilitiesId, this.DiseasesId, this.ById, this.ByDate)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        OpenAddDilag(item) {
            this.SelectedItem = item;
            this.LabruleForm.TestsId = '';
            this.LabruleForm.ResultsId = '';
            this.LabruleForm.Result = '';
            this.LabruleForm.DeliverdOn = '';
            this.LabruleForm.ResultDescriptions = '';
            this.GetAllTests(item.diseasesId);
            this.AddDilog = true;
        },

        GetResult() {
            this.GetAllResults(this.LabruleForm.TestsId);
        },

        submitApplicationsLabForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$blockUI.Start();
                    this.LabruleForm.Id = this.SelectedItem.id;
                    this.$http.AddApplicationsLabResutl(this.LabruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                            this.resetForm(formName);
                            this.AddDilog = false;
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });



                } else {
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        Delete(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteApplicationsLabRequest(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },






        ////////////////////////// Helper /////////////////////

        Refresh() {
            this.MunicipalitiesId = '';
            this.BranchesId = '';
            this.FacilitiesId = '';
            this.DiseasesId = '';
            this.ByDate = '';
            this.GetInfo();
        },

        Back() {
            this.state = 0;
            this.SelectedItem = '';
            this.GetInfo(this.pageNo);
        },

        ChangeDate(date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("YYYY-MM-DD");
        },


    },
};
