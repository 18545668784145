import Swal from "sweetalert2";
import moment from "moment";
import { excelParser } from "./excel-parser";
//import { excelParser } from "./excel-parser";
import HelperMixin from '../../../Shared/HelperMixin.vue'
export default {
    name: "AddUser",
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        await this.GetAllDiseases(2);
        await this.GetAllBranches();

        if (this.loginDetails.userType == 4) {
            this.GetFacilitiesInfoUser(this.loginDetails.municipalitiesid);
        }
       

        this.GetInfo();

    },

    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("DD/MM/YYYY");
        },

        momentTime: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("DD/MM/YYYY");
        },

        momentYear: function (date) {
            if (date === null) {
                return "لا يوجد";
            }
            return moment(date).format("YYYY");
        },

    },


    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,


            Info: [],



            DiseasesId: '',
            MunicipalitiesId: '',
            BranchesId: '',
            FacilitiesId: '',
            ByDate: '',




            //Info
            SelectedItem: '',



            EditDilog: false,
            RequestDilog: false,





            ruleForm: {
                Id: 0,


                DiseasesId: '',
                MunicipalitiesId: '',
                BranchesId: '',
                FacilitiesId: '',

                CountCases: 0,
                Male: 0,
                Fmale: 0,
                UnknownGender: 0,

                Libyan: 0,
                Nlibyan: 0,
                UnknownNationality: 0,

                Deaths: 0,
                VaccinationStatus: 0,
                NotVaccinationStatus: 0,
                UnknownVaccinationStatus: 0,

                LessOne: 0,
                OneToFive: 0,
                SixToTen: 0,
                ElevenToFifteen: 0,
                SixteenToTwenty: 0,
                TwentyOneToTwentyFive: 0,

                TwentySixToThirty: 0,
                ThirtyOneToThirtyFive: 0,
                ThirtySixToForty: 0,
                FortyOneToFortyFive: 0,
                FortySixToFifty: 0,
                GreaterThanFifty: 0,

            },
            rules: {

                DiseasesId: this.$helper.RequiredInput('المرض'),
                BranchesId: this.$helper.Required(),
                FacilitiesId: this.$helper.Required(),
                MunicipalitiesId: this.$helper.Required(),

                CountCases: this.$helper.RequiredInput(' عدد الحالات'),
                Male: this.$helper.RequiredInput(' عدد الذكور'),
                Fmale: this.$helper.RequiredInput(' عدد الإناث'),
                UnknownGender: this.$helper.RequiredInput(' غير مفوفي الجنسية '),

                Libyan: this.$helper.RequiredInput(' عدد الليبين  '),
                Nlibyan: this.$helper.RequiredInput(' عدد الاجانب '),
                UnknownNationality: this.$helper.RequiredInput(' عدد الغير معروف الجنسية '),

                Deaths: this.$helper.RequiredInput(' عدد المتوفين '),
                VaccinationStatus: this.$helper.RequiredInput(' عدد المطعمين '),
                NotVaccinationStatus: this.$helper.RequiredInput(' عدد الغير مطعمين '),
                UnknownVaccinationStatus: this.$helper.RequiredInput(' غير معروف حالةالتطعيم '),

                LessOne: this.$helper.Required(),
                OneToFive: this.$helper.Required(),
                SixToTen: this.$helper.Required(),
                ElevenToFifteen: this.$helper.Required(),
                SixteenToTwenty: this.$helper.Required(),
                TwentyOneToTwentyFive: this.$helper.Required(),

                TwentySixToThirty: this.$helper.Required(),
                ThirtyOneToThirtyFive: this.$helper.Required(),
                ThirtySixToForty: this.$helper.Required(),
                FortyOneToFortyFive: this.$helper.Required(),
                FortySixToFifty: this.$helper.Required(),
                GreaterThanFifty: this.$helper.Required(),

            },






            RequestruleForm: {
                Id: 0,
                ApplicationsWeeklyId: '',
                CountCases: '',
                Desriptions: '',
            },



            Requestrules: {
                CountCases: this.$helper.RequiredInput(' عدد الحالات  '),
            },


        };
    },


    methods: {

        async GetMunicipalitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            this.MunicipalitiesId = '';
            this.Municipalities = [];
            await this.GetAllMunicipalities(this.BranchesId);
            this.GetInfo();
        },


        async GetFacilitiesInfo() {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(this.MunicipalitiesId);
            this.GetInfo();
        },

        async GetFacilitiesInfoUser(MunicipalitiesId) {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(MunicipalitiesId);
            this.GetInfo();
        },






        async Edti_GetMunicipalitiesInfo() {
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];
            this.ruleForm.MunicipalitiesId = '';
            this.Municipalities = [];
            await this.GetAllMunicipalities(this.ruleForm.BranchesId);
        },


        async Edit_GetFacilitiesInfo() {
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(this.ruleForm.MunicipalitiesId);
        },


       



        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }

            if (this.ByDate)
                this.ByDate = this.ChangeDate(this.ByDate);

            this.$blockUI.Start();
            this.$http
                .GetApplicationsWeekly(this.pageNo, this.pageSize, this.BranchesId, this.MunicipalitiesId
                    , this.FacilitiesId, this.DiseasesId, this.ByDate)
                .then((response) => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },

        Refresh() {
            this.MunicipalitiesId = '';
            this.BranchesId = '';
            this.FacilitiesId = '';
            this.DiseasesId = '';
            this.ByDate = '';
            this.GetInfo();
        },




        OpenEditDilaog(item) {
            this.SelectedItem = item;
            this.ruleForm.Id = item.id;
            this.GetAllDiseases(2);
            this.ruleForm.DiseasesId = item.diseasesId;
            this.ruleForm.MunicipalitiesId = item.municipalitiesid;
            this.GetAllMunicipalities(item.branchesId);
            this.ruleForm.BranchesId = item.branchesId;
            this.GetAllFacilities(item.municipalitiesid);
            this.ruleForm.FacilitiesId = item.facilitiesId;
            

            this.ruleForm.CountCases = item.countCases;
            this.ruleForm.Male = item.male;
            this.ruleForm.Fmale = item.fmale;
            this.ruleForm.UnknownGender = item.unknownGender;

            this.ruleForm.Libyan = item.libyan;
            this.ruleForm.Nlibyan = item.nlibyan;
            this.ruleForm.UnknownNationality = item.unknownNationality;

            this.ruleForm.Deaths = item.deaths;
            this.ruleForm.VaccinationStatus = item.vaccinationStatus;
            this.ruleForm.NotVaccinationStatus = item.notVaccinationStatus;
            this.ruleForm.UnknownVaccinationStatus = item.unknownVaccinationStatus;

            this.ruleForm.LessOne = item.lessOne;
            this.ruleForm.OneToFive = item.oneToFive;
            this.ruleForm.SixToTen = item.sixToTen;
            this.ruleForm.ElevenToFifteen = item.elevenToFifteen;
            this.ruleForm.SixteenToTwenty = item.sixteenToTwenty;
            this.ruleForm.TwentyOneToTwentyFive = item.twentyOneToTwentyFive;

            this.ruleForm.TwentySixToThirty = item.twentySixToThirty;
            this.ruleForm.ThirtyOneToThirtyFive = item.thirtyOneToThirtyFive;
            this.ruleForm.ThirtySixToForty = item.thirtySixToForty;
            this.ruleForm.FortyOneToFortyFive = item.fortyOneToFortyFive;
            this.ruleForm.FortySixToFifty = item.fortySixToFifty;
            this.ruleForm.GreaterThanFifty = item.greaterThanFifty;

            this.EditDilog = true;

        },  


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {


                    if (this.loginDetails.userType != 1) {
                        this.ruleForm.FacilitiesId = Number(0);
                    } else {
                        this.ruleForm.FacilitiesId = Number(this.ruleForm.FacilitiesId);
                    }


                    this.ruleForm.CountCases = Number(this.ruleForm.CountCases);
                    this.ruleForm.Male = Number(this.ruleForm.Male);
                    this.ruleForm.Fmale = Number(this.ruleForm.Fmale);
                    this.ruleForm.UnknownGender = Number(this.ruleForm.UnknownGender);

                    this.ruleForm.Libyan = Number(this.ruleForm.Libyan);
                    this.ruleForm.Nlibyan = Number(this.ruleForm.Nlibyan);
                    this.ruleForm.UnknownNationality = Number(this.ruleForm.UnknownNationality);

                    this.ruleForm.Deaths = Number(this.ruleForm.Deaths);
                    this.ruleForm.VaccinationStatus = Number(this.ruleForm.VaccinationStatus);
                    this.ruleForm.NotVaccinationStatus = Number(this.ruleForm.NotVaccinationStatus);
                    this.ruleForm.UnknownVaccinationStatus = Number(this.ruleForm.UnknownVaccinationStatus);

                    this.ruleForm.LessOne = Number(this.ruleForm.LessOne);
                    this.ruleForm.OneToFive = Number(this.ruleForm.OneToFive);
                    this.ruleForm.SixToTen = Number(this.ruleForm.SixToTen);
                    this.ruleForm.ElevenToFifteen = Number(this.ruleForm.ElevenToFifteen);
                    this.ruleForm.SixteenToTwenty = Number(this.ruleForm.SixteenToTwenty);
                    this.ruleForm.TwentyOneToTwentyFive = Number(this.ruleForm.TwentyOneToTwentyFive);
                    this.ruleForm.TwentySixToThirty = Number(this.ruleForm.TwentySixToThirty);
                    this.ruleForm.ThirtyOneToThirtyFive = Number(this.ruleForm.ThirtyOneToThirtyFive);
                    this.ruleForm.ThirtySixToForty = Number(this.ruleForm.ThirtySixToForty);
                    this.ruleForm.FortyOneToFortyFive = Number(this.ruleForm.FortyOneToFortyFive);
                    this.ruleForm.FortySixToFifty = Number(this.ruleForm.FortySixToFifty);
                    this.ruleForm.GreaterThanFifty = Number(this.ruleForm.GreaterThanFifty);

                    this.$blockUI.Start();
                    this.$http.EditApplicationsWeekly(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetFormS(formName);
                            this.GetInfo(this.pageNo);
                            this.EditDilog = false;
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetFormS(formName) {
            this.$refs[formName].resetFields();

            this.ruleForm.CountCases = 0;
            this.ruleForm.Male = 0;
            this.ruleForm.Fmale = 0;
            this.ruleForm.UnknownGender = 0;

            this.ruleForm.Libyan = 0;
            this.ruleForm.Nlibyan = 0;
            this.ruleForm.UnknownNationality = 0;

            this.ruleForm.Deaths = 0;
            this.ruleForm.VaccinationStatus = 0;
            this.ruleForm.NotVaccinationStatus = 0;
            this.ruleForm.UnknownVaccinationStatus = 0;


            //this.ruleForm.OverFive = 0;
            this.ruleForm.LessOne = 0;
            this.ruleForm.OneToFive = 0;
            this.ruleForm.SixToTen = 0;
            this.ruleForm.ElevenToFifteen = 0;
            this.ruleForm.SixteenToTwenty = 0;
            this.ruleForm.TwentyOneToTwentyFive = 0;
            this.ruleForm.TwentySixToThirty = 0;
            this.ruleForm.ThirtyOneToThirtyFive = 0;
            this.ruleForm.ThirtySixToForty = 0;
            this.ruleForm.FortyOneToFortyFive = 0;
            this.ruleForm.FortySixToFifty = 0;
            this.ruleForm.GreaterThanFifty = 0;
        },

        Delete(Id) {
            Swal.fire({
                title: "هـل انت متأكد من عملية الحذف ؟",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteApplicationsWeekly(Id)
                        .then((response) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.GetInfo();
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            });
        },










        OpenRequestDilog(item) {
            this.SelectedItem = item;
            this.RequestDilog = true;
        },

        submitRequestForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.RequestruleForm.Id = Number(0);
                    this.RequestruleForm.CountCases = Number(this.RequestruleForm.CountCases);
                    this.RequestruleForm.ApplicationsWeeklyId = this.SelectedItem.id;
                    this.$blockUI.Start();
                    this.$http.AddRequest(this.RequestruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.RequestDilog = false;
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بالعملية  ', err.response.data);
                        });


                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },












        FilterByJob() {
            this.Job = [];
            let code = "";
            if (document.getElementById('selectInputPass') != null) {

                if (document.getElementById('selectInputPass').value == null || document.getElementById('selectInputPass').value == '')
                    return;

                code = document.getElementById('selectInputPass').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetJobsById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Job = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    //console.error(err);
                });
        },

        FilterByPass1() {
            this.Job = [];
            let code = "";
            if (document.getElementById('selectInputPass1') != null) {

                if (document.getElementById('selectInputPass1').value == null || document.getElementById('selectInputPass1').value == '')
                    return;

                code = document.getElementById('selectInputPass1').value;
            }
            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetJobsById(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Job = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                });
        },








        /////////////////////////////////////////////// Helper //////////////////////////////////////////////

        Back() {
            this.state = 0;
            this.SelectedItem = '';
            this.RemoveAllAttachment();
            this.GetInfo(this.pageNo);
        },

        ChangeDate(date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format("YYYY-MM-DD");
        },


        ExportData() {
            //let dataModefide = [];
            //this.Info.forEach(element => {
            //    let schema = {
            //        'رقم الملف': element.fileNumber,

            //        'اسم المريض': element.name,
            //        'الجنسية': element.nationalyName,
            //        'الرقم الوطني': element.nid,
            //        'رقم جواز السفر': element.passport,
            //        'المدينه': element.cityName,

            //        'الحالة الاجتامعية ': element.maritalStatus == 1 ? "اعزب" : element.maritalStatus == 2 ? "متزوج" : "ارمل",
            //        'الجنس': element.gender == 1 ? "ذكر" : "انثى",
            //        'تاريخ الميلاد': element.dateOfBirth?.split("T")[0],
            //        'العنوان': element.address,
            //        'مكان العمل': element.workplace,

            //        'المدينة ': element.cityCenterName,
            //        'البلدية': element.municipalitiesName,
            //        'مركز الكلى': element.kidneyCenterName,
            //        'فصيلة الدم': element.bloodType == 1 ? "O+" : element.bloodType == 2 ? "O-" : element.bloodType == 3 ? "A+" : element.bloodType == 4 ? "A-" : element.bloodType == 5 ? "B+" : element.bloodType == 6 ? "B-" : element.bloodType == 7 ? "AB+" : element.bloodType == 7 ? "AB-" : "غير متوفر",
            //        'التحاليل الفيروسية': element.viralAssays == 1 ? "Negative" : element.viralAssays == 2 ? "C-Postive" : element.viralAssays == 3 ? "B-Postive" : element.viralAssays == 4 ? "HIV" : "غير متوفر",
            //        'تاريخ الاصابة بالفشل': element.kidneyFailureDate?.split("T")[0],
            //        'سبب الاصالة': element.kidneyFailureCause == 1 ? "وراثي" : element.kidneyFailureCause == 2 ? "ضغظ دم" : element.kidneyFailureCause == 3 ? "سكرى" : element.kidneyFailureCause == 4 ? "مسالك" : element.kidneyFailureCause == 5 ? "اخرى" : "غير متوفر",

            //        'حالة الغسيل': element.status == 1 ? "مستمر" : element.status == 2 ? "زراعة" : element.status == 3 ? "متوفي" : "غير متوفر",
            //        'اسم الشركة': element.companyName,
            //        'اسم الجهاز': element.deviceName,
            //        'اسم الفلتر': element.filterName,

            //        'منفذ العملية': element.createdBy,
            //        'تاريخ الاضافة': element.createdOn?.split("T")[0]
            //    }
            //    dataModefide.push(schema);
            //});
            excelParser().exportDataFromJSON(this.Info, null, null);
        },

    },
};
