////import Swal from "sweetalert2";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../../Shared/HelperMixin.vue'

export default {
    name: "Add",
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        await this.GetAllDiseases(1);
        await this.GetAllBranches();
        await this.GetAllCountries();
        await this.GetAllNationalities();

        if (this.loginDetails.userType == 4) {
            this.GetFacilitiesInfoUser(this.loginDetails.municipalitiesid);
        }


    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format("MMMM Do YYYY");
        },
    },
    data() {
        return {



            ruleForm: {
                Id: 0,

                DiseasesId: '',
                MunicipalitiesId: '',
                BranchesId: '',
                LMunicipalitiesId: '',
                LBranchesId: '',
                FacilitiesId: '',
                LocalityId: '',

                OccupationId: null,
                OldOccupationId: null,
                PatientsStatusId: null,
                SiteOfBleedingId: null,
                LeishmaniaLesionSitesId: null,
                ChronicDiseasesId: null,
                TraveleToId: null,
                ProceduresId: null,
                RelationshipId: null,
                AnimalId: null,
                AnimalContactId: null,
                AnalysisExaminationsId: null,
                ComplicationsId: null,
                InitialDiagnosisId: null,
                ReferralId: null,
                TreatmentId: null,
                RapidTestId: null,
                SamplesId: null,
                TestId: null,
                ResultId: null,
                SymptomsId: null,



                //OtherDetatils: '',

                Name: '',
                DateOfBirth: '',
                Phone: '',
                Gender: '',

                NationalitieId: 1,
                Nid: '',
                Passport: '',
                Address: '',
                
                MaritalStatus: '',
                IsPregnant: '',
                School: '',
                PatientAdmitted: '',

                AdmissionOn: null,
                DeathOn: null,
                SymptomsOn: null,
                MaxSizeLeishmaniasisGranule: null,

                NumberLeishmaniasisGranules: null,
                OtherSymptoms: '',
                FirstVisitOn: null,
                PreviousInfections: '',
                ReceivedAntibioticTreatment: '',
                BloodTransfusion: '',
                BloodTransfusionOn: null,
                PreviousHospitalization: '',
                MotherInfectedDuringPregnancy: '',
                MothersMedicalFollowup: '',
                MotherUnderMedicalSupervision: '',
                MotherVisitedMedicalFacility: '',
                ConcomitantViralInfection: '',
                TraveledOutsideArea: '',
                PlaceOfVisit: '',
                VisitOn: null,
                TraveledOutsideCountry: '',
                TravelOn: null,
                ReturnOn: null,
                PlacesVisited: '',
                AcuteFlaccidParalysisCases: '',
                ContactWithSimilarSymptoms: '',
                ContactWithInfectedCase: '',
                FirstContactOn: null,
                OtherCasesWithSameSymptoms: '',
                VaccinationStatus: '',
                OralPolioVaccineDoses: null,
                AdditionalDoses: null,
                NumberOfDoses: null,
                LastDoseOn: null,
                SourceOfInformation: '',
                AntibodiesTested: '',
                MotherVaccinatedAgainstTetanus: '',
                TouchedAnimalWithSymptoms: '',
                ExposureOn: null,
                DealtWithSlaughtering: '',
                ContactWithAnimals: '',
                BiteStingOn: null,
                SeenAscorpion: null,
                ColorOfScorpion: '',
                SizeOfScorpion: null,
                LocationOfAnimal: '',
                AnimalOwnerNameAddress: '',
                AnimalDeathsLastMonth: '',
                AnimalDeathsLastMonthCount: null,
                AnimalMiscarriagesLastMonth: '',
                AnimalMiscarriagesLastMonthCount: null,
                AnimalStatus: '',
                AnimalVaccinated: '',
                AnimalLabConfirmedInfected: '',
                ConsumedAnimalProducts: '',
                ConsumedMeatFromSickAnimal: '',
                FoodSource: '',
                SharedMeal: '',
                FoodTypes: '',
                MealOn: null,
                DrinkingWaterSource: null,
                OtherDrinkingWaterSource: '',
                WaterBasinsInArea: '',
                SleepsInMosquitoNet: '',
                SleepingArea: '',
                Sanitation: '',
                NumberHydatidCysts: null,
                LocationOfInjuryId: null,
                PreviousSurgeryHydatidCysts: '',
                WoundSignificance: '',
                SkinLesionsSameDevelopment: '',
                SkinLesionsSameSize: '',
                SkinLesionsDeep: '',
                LocationSkinLesionsId: null,
                DevelopedUlcers: '',
                HasAids: '',
                AwareOfDisease: '',
                SexualIntercourseLastThreeMonths: '',
                RelationshipInsideLibya: '',
                BirthInHealthFacility: '',
                BirthUnderMedicalSupervision: '',
                UmbilicalCordCutMethod: '',
                UmbilicalCordBandagedMethod: '',
                NewbornCryingBreastfeeding: '',
                NewbornBreastfeedingProblems: '',
                NewbornConvulsions: '',
                ExaminedByHealthAuthority: '',
                ContaminatedWound: '',
                ForeignBodyEntered: '',
                SharedUnsterileNeedles: '',
                ReceivedLettersParcels: '',
                InjuryDetectionMethod: '',
            },
            rules: {

                DiseasesId: this.$helper.RequiredInput('المرض'),
                MunicipalitiesId: this.$helper.Required(),
                LMunicipalitiesId: this.$helper.Required(),
                LocalityId: this.$helper.Required(),
                BranchesId: this.$helper.Required(),
                LBranchesId: this.$helper.Required(),
                FacilitiesId: this.$helper.Required(),

            
                //OtherDetatils: '',

                Name: this.$helper.Required(),
                DateOfBirth: this.$helper.Required(),
                Phone: [
                    {
                        required: true,
                        message: "الرجاء إدخال رقم الهاتف",
                        trigger: "blur",
                    },
                    {
                        min: 9,
                        max: 13,
                        message: "يجب ان يكون طول رقم الهاتف 9 ارقام علي الاقل",
                        trigger: "blur",
                    },
                    { required: true, pattern: /^[0-9]*$/, message: 'الرجاء إدخال ارقام فقط', trigger: 'blur' }
                ],
                Gender: this.$helper.Required(),

                NationalitieId: this.$helper.RequiredInput('الجنسية'),
                Nid: this.$helper.NID(),
                Passport: this.$helper.RequiredInput('رقم جواز السفر او رقم الهوية'),
                Address: this.$helper.Required(),

                MaritalStatus: '',
                IsPregnant: this.$helper.Required(),
                School: '',
                PatientAdmitted: this.$helper.Required(),

                AdmissionOn: this.$helper.Required(),
                DeathOn: this.$helper.Required(),
               
            },





        };
    },

    methods: {

        async GetMunicipalitiesInfo() {
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];
            this.ruleForm.MunicipalitiesId = '';
            this.Municipalities = [];

            await this.GetAllMunicipalities(this.ruleForm.BranchesId);
        },

        async GetFacilitiesInfo() {
            this.ruleForm.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(this.ruleForm.MunicipalitiesId);
        },


        async LGetMunicipalitiesInfo() {
            this.ruleForm.LocalityId = '';
            this.Locality = [];
            this.ruleForm.LMunicipalitiesId = '';
            await this.LGetAllMunicipalities(this.ruleForm.LBranchesId);
        },

        async GetAllLocalityInfo() {
            this.ruleForm.LocalityId = '';
            await this.GetAllLocality(this.ruleForm.LMunicipalitiesId);
        },

        async GetFacilitiesInfoUser(MunicipalitiesId) {
            this.FacilitiesId = '';
            this.Facilities = [];
            await this.GetAllFacilities(MunicipalitiesId);
        },

        async ChangeDiseases() {
            await this.GetAllAnalysisExaminations(this.ruleForm.DiseasesId);
            await this.GetAllAnimals(this.ruleForm.DiseasesId);
            await this.GetAllChronicDiseases(this.ruleForm.DiseasesId);
            await this.GetAllComplications(this.ruleForm.DiseasesId);
            await this.GetAllInitialDiagnosis(this.ruleForm.DiseasesId);
            await this.GetAllLaboratories(this.ruleForm.MunicipalitiesId);
            await this.GetAllLesionSites(this.ruleForm.DiseasesId);
            await this.GetAllOccupations(this.ruleForm.DiseasesId);
            await this.GetAllPatientsStatus(this.ruleForm.DiseasesId);
            await this.GetAllProcedures(this.ruleForm.DiseasesId);
            await this.GetAllRapidTests(this.ruleForm.DiseasesId);
            await this.GetAllReferrals(this.ruleForm.DiseasesId);
            await this.GetAllRelationship(this.ruleForm.DiseasesId);
            await this.GetAllResults(this.ruleForm.DiseasesId);
            await this.GetAllSamples(this.ruleForm.DiseasesId);
            await this.GetAllSiteOfBleedings(this.ruleForm.DiseasesId);
            await this.GetAllTests(this.ruleForm.DiseasesId);
            await this.GetAllTreatments(this.ruleForm.DiseasesId);
            await this.GetAllAnimalContacts(this.ruleForm.DiseasesId);
            await this.GetAllSymptoms(this.ruleForm.DiseasesId);
        },


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.ruleForm.Id = Number(0);
                    if (this.loginDetails.userType != 1 && this.loginDetails.userType != 4) {
                        this.ruleForm.FacilitiesId = Number(0);
                    } else {
                        this.ruleForm.FacilitiesId = Number(this.ruleForm.FacilitiesId);
                    }

                    




                    if (!this.ruleForm.MaritalStatus)
                        this.ruleForm.MaritalStatus = Number(0);

                    if (!this.ruleForm.IsPregnant) {
                        this.ruleForm.IsPregnant = Number(0);
                    } else {
                        this.ruleForm.IsPregnant = Number(this.ruleForm.IsPregnant);
                    }

                    if (!this.ruleForm.PatientAdmitted) {
                        this.ruleForm.PatientAdmitted = Number(0);
                    } else {
                        this.ruleForm.PatientAdmitted = Number(this.ruleForm.PatientAdmitted);
                    }

                    if (this.ruleForm.MaxSizeLeishmaniasisGranule)
                        this.ruleForm.MaxSizeLeishmaniasisGranule = Number(this.ruleForm.MaxSizeLeishmaniasisGranule);

                    if (this.ruleForm.NumberLeishmaniasisGranules)
                        this.ruleForm.NumberLeishmaniasisGranules = Number(this.ruleForm.NumberLeishmaniasisGranules);


                    if (!this.ruleForm.Gender) {
                        this.ruleForm.Gender = Number(0);
                    } else {
                        this.ruleForm.Gender = Number(this.ruleForm.Gender);
                    }

                    if (!this.ruleForm.PreviousInfections) {
                        this.ruleForm.PreviousInfections = Number(0);
                    } else {
                        this.ruleForm.PreviousInfections = Number(this.ruleForm.PreviousInfections);
                    }

                    if (!this.ruleForm.ReceivedAntibioticTreatment) {
                        this.ruleForm.ReceivedAntibioticTreatment = Number(0);
                    } else {
                        this.ruleForm.ReceivedAntibioticTreatment = Number(this.ruleForm.ReceivedAntibioticTreatment);
                    }

                    if (!this.ruleForm.BloodTransfusion) {
                        this.ruleForm.BloodTransfusion = Number(0);
                    } else {
                        this.ruleForm.BloodTransfusion = Number(this.ruleForm.BloodTransfusion);
                    }

                    if (!this.ruleForm.PreviousHospitalization) {
                        this.ruleForm.PreviousHospitalization = Number(0);
                    } else {
                        this.ruleForm.PreviousHospitalization = Number(this.ruleForm.PreviousHospitalization);
                    }


                    if (!this.ruleForm.MotherInfectedDuringPregnancy) {
                        this.ruleForm.MotherInfectedDuringPregnancy = Number(0);
                    } else {
                        this.ruleForm.MotherInfectedDuringPregnancy = Number(this.ruleForm.MotherInfectedDuringPregnancy);
                    }

                    if (!this.ruleForm.MotherUnderMedicalSupervision) {
                        this.ruleForm.MotherUnderMedicalSupervision = Number(0);
                    } else {
                        this.ruleForm.MotherUnderMedicalSupervision = Number(this.ruleForm.MotherUnderMedicalSupervision);
                    }

                    if (!this.ruleForm.MotherVisitedMedicalFacility) {
                        this.ruleForm.MotherVisitedMedicalFacility = Number(0);
                    } else {
                        this.ruleForm.MotherVisitedMedicalFacility = Number(this.ruleForm.MotherVisitedMedicalFacility);
                    }

                    if (!this.ruleForm.ConcomitantViralInfection) {
                        this.ruleForm.ConcomitantViralInfection = Number(0);
                    } else {
                        this.ruleForm.ConcomitantViralInfection = Number(this.ruleForm.ConcomitantViralInfection);
                    }

                    if (!this.ruleForm.TraveledOutsideArea) {
                        this.ruleForm.TraveledOutsideArea = Number(0);
                    } else {
                        this.ruleForm.TraveledOutsideArea = Number(this.ruleForm.TraveledOutsideArea);
                    }

                    if (!this.ruleForm.TraveledOutsideCountry) {
                        this.ruleForm.TraveledOutsideCountry = Number(0);
                    } else {
                        this.ruleForm.TraveledOutsideCountry = Number(this.ruleForm.TraveledOutsideCountry);
                    }

                    if (!this.ruleForm.AcuteFlaccidParalysisCases) {
                        this.ruleForm.AcuteFlaccidParalysisCases = Number(0);
                    } else {
                        this.ruleForm.AcuteFlaccidParalysisCases = Number(this.ruleForm.AcuteFlaccidParalysisCases);
                    }

                    if (!this.ruleForm.ContactWithSimilarSymptoms) {
                        this.ruleForm.ContactWithSimilarSymptoms = Number(0);
                    } else {
                        this.ruleForm.ContactWithSimilarSymptoms = Number(this.ruleForm.ContactWithSimilarSymptoms);
                    }

                    if (!this.ruleForm.ContactWithInfectedCase) {
                        this.ruleForm.ContactWithInfectedCase = Number(0);
                    } else {
                        this.ruleForm.ContactWithInfectedCase = Number(this.ruleForm.ContactWithInfectedCase);
                    }

                    if (!this.ruleForm.OtherCasesWithSameSymptoms) {
                        this.ruleForm.OtherCasesWithSameSymptoms = Number(0);
                    } else {
                        this.ruleForm.OtherCasesWithSameSymptoms = Number(this.ruleForm.OtherCasesWithSameSymptoms);
                    }

                    if (!this.ruleForm.VaccinationStatus) {
                        this.ruleForm.VaccinationStatus = Number(0);
                    } else {
                        this.ruleForm.VaccinationStatus = Number(this.ruleForm.VaccinationStatus);
                    }

                    if (this.ruleForm.OralPolioVaccineDoses)
                        this.ruleForm.OralPolioVaccineDoses = Number(this.ruleForm.OralPolioVaccineDoses);

                    if (this.ruleForm.AdditionalDoses)
                        this.ruleForm.AdditionalDoses = Number(this.ruleForm.AdditionalDoses);

                    if (this.ruleForm.NumberOfDoses)
                        this.ruleForm.NumberOfDoses = Number(this.ruleForm.NumberOfDoses);


                    if (!this.ruleForm.AntibodiesTested) {
                        this.ruleForm.AntibodiesTested = Number(0);
                    } else {
                        this.ruleForm.AntibodiesTested = Number(this.ruleForm.AntibodiesTested);
                    }

                    if (!this.ruleForm.MotherVaccinatedAgainstTetanus) {
                        this.ruleForm.MotherVaccinatedAgainstTetanus = Number(0);
                    } else {
                        this.ruleForm.MotherVaccinatedAgainstTetanus = Number(this.ruleForm.MotherVaccinatedAgainstTetanus);
                    }

                    if (!this.ruleForm.TouchedAnimalWithSymptoms) {
                        this.ruleForm.TouchedAnimalWithSymptoms = Number(0);
                    } else {
                        this.ruleForm.TouchedAnimalWithSymptoms = Number(this.ruleForm.TouchedAnimalWithSymptoms);
                    }

                    if (!this.ruleForm.DealtWithSlaughtering) {
                        this.ruleForm.DealtWithSlaughtering = Number(0);
                    } else {
                        this.ruleForm.DealtWithSlaughtering = Number(this.ruleForm.DealtWithSlaughtering);
                    }

                    if (!this.ruleForm.ContactWithAnimals) {
                        this.ruleForm.ContactWithAnimals = Number(0);
                    } else {
                        this.ruleForm.ContactWithAnimals = Number(this.ruleForm.ContactWithAnimals);
                    }

                    if (!this.ruleForm.SeenAscorpion) {
                        this.ruleForm.SeenAscorpion = Number(0);
                    } else {
                        this.ruleForm.SeenAscorpion = Number(this.ruleForm.SeenAscorpion);
                    }

                    if (this.ruleForm.SizeOfScorpion)
                        this.ruleForm.SizeOfScorpion = Number(this.ruleForm.SizeOfScorpion);

                    if (!this.ruleForm.AnimalDeathsLastMonth) {
                        this.ruleForm.AnimalDeathsLastMonth = Number(0);
                    } else {
                        this.ruleForm.AnimalDeathsLastMonth = Number(this.ruleForm.AnimalDeathsLastMonth);
                    }

                    if (this.ruleForm.AnimalDeathsLastMonthCount)
                        this.ruleForm.AnimalDeathsLastMonthCount = Number(this.ruleForm.AnimalDeathsLastMonthCount);

                    if (!this.ruleForm.AnimalMiscarriagesLastMonth) {
                        this.ruleForm.AnimalMiscarriagesLastMonth = Number(0);
                    } else {
                        this.ruleForm.AnimalMiscarriagesLastMonth = Number(this.ruleForm.AnimalMiscarriagesLastMonth);
                    }

                    if (this.ruleForm.AnimalMiscarriagesLastMonthCount)
                        this.ruleForm.AnimalMiscarriagesLastMonthCount = Number(this.ruleForm.AnimalMiscarriagesLastMonthCount);

                    if (!this.ruleForm.AnimalVaccinated) {
                        this.ruleForm.AnimalVaccinated = Number(0);
                    } else {
                        this.ruleForm.AnimalVaccinated = Number(this.ruleForm.AnimalVaccinated);
                    }

                    if (!this.ruleForm.AnimalLabConfirmedInfected) {
                        this.ruleForm.AnimalLabConfirmedInfected = Number(0);
                    } else {
                        this.ruleForm.AnimalLabConfirmedInfected = Number(this.ruleForm.AnimalLabConfirmedInfected);
                    }

                    if (!this.ruleForm.ConsumedAnimalProducts) {
                        this.ruleForm.ConsumedAnimalProducts = Number(0);
                    } else {
                        this.ruleForm.ConsumedAnimalProducts = Number(this.ruleForm.ConsumedAnimalProducts);
                    }

                    if (!this.ruleForm.ConsumedMeatFromSickAnimal) {
                        this.ruleForm.ConsumedMeatFromSickAnimal = Number(0);
                    } else {
                        this.ruleForm.ConsumedMeatFromSickAnimal = Number(this.ruleForm.ConsumedMeatFromSickAnimal);
                    }

                    if (!this.ruleForm.SharedMeal) {
                        this.ruleForm.SharedMeal = Number(0);
                    } else {
                        this.ruleForm.SharedMeal = Number(this.ruleForm.SharedMeal);
                    }

                    if (!this.ruleForm.WaterBasinsInArea) {
                        this.ruleForm.WaterBasinsInArea = Number(0);
                    } else {
                        this.ruleForm.WaterBasinsInArea = Number(this.ruleForm.WaterBasinsInArea);
                    }

                    if (!this.ruleForm.SleepsInMosquitoNet) {
                        this.ruleForm.SleepsInMosquitoNet = Number(0);
                    } else {
                        this.ruleForm.SleepsInMosquitoNet = Number(this.ruleForm.SleepsInMosquitoNet);
                    }

                    if (!this.ruleForm.SleepingArea) {
                        this.ruleForm.SleepingArea = Number(0);
                    } else {
                        this.ruleForm.SleepingArea = Number(this.ruleForm.SleepingArea);
                    }

                    if (!this.ruleForm.Sanitation) {
                        this.ruleForm.Sanitation = Number(0);
                    } else {
                        this.ruleForm.Sanitation = Number(this.ruleForm.Sanitation);
                    }

                    if (this.ruleForm.NumberHydatidCysts)
                        this.ruleForm.NumberHydatidCysts = Number(this.ruleForm.NumberHydatidCysts);

                    if (!this.ruleForm.PreviousSurgeryHydatidCysts) {
                        this.ruleForm.PreviousSurgeryHydatidCysts = Number(0);
                    } else {
                        this.ruleForm.PreviousSurgeryHydatidCysts = Number(this.ruleForm.PreviousSurgeryHydatidCysts);
                    }

                    if (!this.ruleForm.SkinLesionsSameDevelopment) {
                        this.ruleForm.SkinLesionsSameDevelopment = Number(0);
                    } else {
                        this.ruleForm.SkinLesionsSameDevelopment = Number(this.ruleForm.SkinLesionsSameDevelopment);
                    }

                    if (!this.ruleForm.SkinLesionsSameSize) {
                        this.ruleForm.SkinLesionsSameSize = Number(0);
                    } else {
                        this.ruleForm.SkinLesionsSameSize = Number(this.ruleForm.SkinLesionsSameSize);
                    }


                    if (!this.ruleForm.SkinLesionsSameSize) {
                        this.ruleForm.SkinLesionsSameSize = Number(0);
                    } else {
                        this.ruleForm.SkinLesionsSameSize = Number(this.ruleForm.SkinLesionsSameSize);
                    }

                    if (!this.ruleForm.SkinLesionsDeep) {
                        this.ruleForm.SkinLesionsDeep = Number(0);
                    } else {
                        this.ruleForm.SkinLesionsDeep = Number(this.ruleForm.SkinLesionsDeep);
                    }

                    if (!this.ruleForm.DevelopedUlcers) {
                        this.ruleForm.DevelopedUlcers = Number(0);
                    } else {
                        this.ruleForm.DevelopedUlcers = Number(this.ruleForm.DevelopedUlcers);
                    }

                    if (!this.ruleForm.HasAids) {
                        this.ruleForm.HasAids = Number(0);
                    } else {
                        this.ruleForm.HasAids = Number(this.ruleForm.HasAids);
                    }

                    if (!this.ruleForm.AwareOfDisease) {
                        this.ruleForm.AwareOfDisease = Number(0);
                    } else {
                        this.ruleForm.AwareOfDisease = Number(this.ruleForm.AwareOfDisease);
                    }

                    if (!this.ruleForm.SexualIntercourseLastThreeMonths) {
                        this.ruleForm.SexualIntercourseLastThreeMonths = Number(0);
                    } else {
                        this.ruleForm.SexualIntercourseLastThreeMonths = Number(this.ruleForm.SexualIntercourseLastThreeMonths);
                    }

                    if (!this.ruleForm.RelationshipInsideLibya) {
                        this.ruleForm.RelationshipInsideLibya = Number(0);
                    } else {
                        this.ruleForm.RelationshipInsideLibya = Number(this.ruleForm.RelationshipInsideLibya);
                    }

                    if (!this.ruleForm.BirthInHealthFacility) {
                        this.ruleForm.BirthInHealthFacility = Number(0);
                    } else {
                        this.ruleForm.BirthInHealthFacility = Number(this.ruleForm.BirthInHealthFacility);
                    }

                    if (!this.ruleForm.BirthUnderMedicalSupervision) {
                        this.ruleForm.BirthUnderMedicalSupervision = Number(0);
                    } else {
                        this.ruleForm.BirthUnderMedicalSupervision = Number(this.ruleForm.BirthUnderMedicalSupervision);
                    }

                    if (!this.ruleForm.NewbornCryingBreastfeeding) {
                        this.ruleForm.NewbornCryingBreastfeeding = Number(0);
                    } else {
                        this.ruleForm.NewbornCryingBreastfeeding = Number(this.ruleForm.NewbornCryingBreastfeeding);
                    }

                    if (!this.ruleForm.NewbornBreastfeedingProblems) {
                        this.ruleForm.NewbornBreastfeedingProblems = Number(0);
                    } else {
                        this.ruleForm.NewbornBreastfeedingProblems = Number(this.ruleForm.NewbornBreastfeedingProblems);
                    }

                    if (!this.ruleForm.NewbornConvulsions) {
                        this.ruleForm.NewbornConvulsions = Number(0);
                    } else {
                        this.ruleForm.NewbornConvulsions = Number(this.ruleForm.NewbornConvulsions);
                    }

                    if (!this.ruleForm.ExaminedByHealthAuthority) {
                        this.ruleForm.ExaminedByHealthAuthority = Number(0);
                    } else {
                        this.ruleForm.ExaminedByHealthAuthority = Number(this.ruleForm.ExaminedByHealthAuthority);
                    }

                    if (!this.ruleForm.ContaminatedWound) {
                        this.ruleForm.ContaminatedWound = Number(0);
                    } else {
                        this.ruleForm.ContaminatedWound = Number(this.ruleForm.ContaminatedWound);
                    }

                    if (!this.ruleForm.ForeignBodyEntered) {
                        this.ruleForm.ForeignBodyEntered = Number(0);
                    } else {
                        this.ruleForm.ForeignBodyEntered = Number(this.ruleForm.ForeignBodyEntered);
                    }

                    if (!this.ruleForm.SharedUnsterileNeedles) {
                        this.ruleForm.SharedUnsterileNeedles = Number(0);
                    } else {
                        this.ruleForm.SharedUnsterileNeedles = Number(this.ruleForm.SharedUnsterileNeedles);
                    }

                    if (!this.ruleForm.ReceivedLettersParcels) {
                        this.ruleForm.ReceivedLettersParcels = Number(0);
                    } else {
                        this.ruleForm.ReceivedLettersParcels = Number(this.ruleForm.ReceivedLettersParcels);
                    }


                    this.$blockUI.Start();
                    this.$http.AddApplications(this.ruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },



    },
};
